import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import LockIcon from "@mui/icons-material/Lock";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React from "react";
import { useDispatch } from "react-redux";
import "../css/layout/login.css";
import { loginUser } from "../api/api";
const Login = () => {
  const dispatch = useDispatch();
  const dispatch_data = (type, data) => {
    dispatch({
      type: type,
      data: data,
    });
  };

  const [state, setState] = React.useState({
    logindata: [],
    username: "",
    password: "",
  });

  const onChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  // const onLogin = (e) => {
  //   e.preventDefault();
  //   let data = {
  //     username: state.username,
  //     password: state.password,
  //   };
  //   dispatch(login(data))
  // };
  // const deletekokis = () => {
  //   document.cookie = "token=" + ";expires";
  // };

  const getUrlVars = () => {
    var vars = {};
    var parts = window.location.href.replace(
      /[?&]+([^=&]+)=([^&]*)/gi,
      function (m, key, value) {
        vars[key] = value;
      }
    );
    return vars;
  };
  const getUrlParam = (parameter, defaultvalue) => {
    var urlparameter = defaultvalue;
    if (window.location.href.indexOf(parameter) > -1) {
      urlparameter = getUrlVars()[parameter];
    }
    return urlparameter;
  };
  const login = (e) => {
    e.preventDefault();
    const userdata = {
      username: state.username,
      password: state.password,
      app_id: 0,
    };
    loginUser("/auth/Loginv2", userdata).then((result) => {
      setState((prev) => ({
        ...prev,
        logindata: result.data,
      }));
      localStorage.setItem("u", state.logindata);
      if (result.res === "Success") {
        window.location.replace(getUrlVars()["goto"] + "?u=" + result.data);
      } else {
        alert("Invalid");
      }
    });
  };
  return (
    <Grid container>
      <Grid item xs={7}>
        <form onSubmit={login}>
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            spacing={2}
            style={{ height: "100vh" }}
          >
            <Typography variant="h5" className="main-color-bold">
              Sign in to GZONETECH PH
            </Typography>
            <Typography variant="caption" className="sec-color">
              Powered by Gzonetech Inc.
            </Typography>
            <Stack direction="column" spacing={2} style={{ width: "50%" }}>
              <TextField
                size="small"
                className="txtfield-shadow"
                placeholder="Login ID"
                name="username"
                required
                onChange={onChange}
                value={state.username}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircleOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                size="small"
                className="txtfield-shadow"
                placeholder="Password"
                onChange={onChange}
                value={state.password}
                name="password"
                required
                type="password"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
            <Button type="submit" variant="contained" className="main-btcolor">
              Sign in
            </Button>
          </Stack>
        </form>
      </Grid>
      <Grid item xs={5}></Grid>
    </Grid>
  );
};

export default Login;
